import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="modal"
export default class extends Controller {
  connect() {

    let selectedClientType = $('.js-client-type-option:checked').val();
    //console.log(selectedClientType)

    $('.js-client-type-option').on('change', function(){
      selectedClientType = $('.js-client-type-option:checked').val();
      //console.log(selectedClientType)
      clientTypeSelected(selectedClientType);
    })

   
    clientTypeSelected(selectedClientType);

    function clientTypeSelected(clientType){
      let selectedTemplate = '';

      $('.js-fields-client-type').addClass('display-none');
      $('.js-fields-client-type').find(':input, select').prop('disabled', true);

      if(['person', 'company'].includes(clientType)){
        selectedTemplate = '.js-fields-client-type.fields-' + clientType;
        // console.log(selectedTemplate);
      }else{
        // the default template
        selectedTemplate = '.js-fields-client-type.fields-person';
      };


      $(selectedTemplate).find(':input, select').prop('disabled', false);
      $(selectedTemplate).removeClass('display-none');
    };

    hasBulgarianCitizenshipChanged($('.js-has-bulgarian-citizenship').val());

    $('.js-has-bulgarian-citizenship').on('change', function(){
      hasBulgarianCitizenshipChanged($(this).val());
    })

    function hasBulgarianCitizenshipChanged(hasBulgarianCitizenship){
      if(hasBulgarianCitizenship == 'true'){
        $('.js-non-bulgarian-citizenship-fields').find(':input, select').prop('disabled', true);
        $('.js-non-bulgarian-citizenship-fields').addClass('display-none');

        $('.js-pin-label').text('ЕГН');
      }else{
        $('.js-non-bulgarian-citizenship-fields').find(':input, select').prop('disabled', false);
        $('.js-non-bulgarian-citizenship-fields').removeClass('display-none');

        $('.js-pin-label').text('ЛНЧ');
      }
    }

    let phoneNumbersContainer = $('#phone-numbers-container');
    if($('#phone-numbers-container').data('click-attached') != '1'){
      $('#phone-numbers-container').on('click', '.js-delete-number', function(){
        if (confirm('Сигурни ли сте, че искате да изтриете този номер?')){
          let parentContainer = $(this).parents('.js-number-container');
          parentContainer.addClass('display-none');
          let input = parentContainer.find('.js-delete-number-input');
          input.val(1);

          let otherInputs = parentContainer.find('input, select');
          let otherInput;
          for (var i = otherInputs.length - 1; i >= 0; i--) {
            otherInput = $(otherInputs[i]);
            if(!otherInput.hasClass('.js-delete-number-input')){
              otherInput.removeAttr('required');
              otherInput.removeAttr('pattern');
            }
          }
        }
      });

      $('#phone-numbers-container').data('click-attached', '1');
    }
  }
}
