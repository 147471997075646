import { Controller } from "@hotwired/stimulus"
import 'inputmask/dist/inputmask.js';

export default class extends Controller {

  connect() {

    // document.addEventListener('turbo:frame-render', function(e){
    //   // console.log('turbo:frame-render');
    //   bindDateMask();
    // });

    document.addEventListener('turbo:before-cache', function () {
      // console.log('turbo:before-cache');
      unbindDateMask();
    });

    bindDateMask();

    function bindDateMask(){
      const elements = document.getElementsByClassName('date-mask');

      var im = new Inputmask("99.99.9999", { 
        placeholder: 'dd.mm.yyyy', 
        outputFormat: 'yyyy-mm-dd',
        clearIncomplete: true, 
        removeMaskOnSubmit: false,
        // onBeforeMask: function (value, opts) {
        //  // format the rails date output format "yyyy/mm/dd" to the expected format "dd/mm/yyyy"
        //  //let formattedValue = Inputmask.unmask(value, { mask: "9999-99-99", inputFormat: "yyyy-mm-dd", outputFormat: "dd/mm/yyyy"})

        //  let formattedValue = Inputmask.unmask(value, { alias: "datetime", inputFormat: "yyyy-mm-dd", outputFormat: "dd/mm/yyyy"});

        //  // console.log(value)
        //  // console.log(formattedValue)

        //  return formattedValue;
        // },
        onBeforePaste: function (pastedValue, opts) {
          // pastedValue = pastedValue.toLowerCase();
          // return pastedValue.replace("mailto:", "");
          // console.log(pastedValue);
          // return pastedValue;

          return formatPasedDate(pastedValue);
        },
        oncomplete: function(){
          // console.log($(this).val())
        } 
      });

      function formatPasedDate(pastedValue){
        // Replace month names
        const monthsMapping = {
          'jan': '01',
          'january': '01',
          'feb':'02',
          'february': '02',
          'mar': '03',
          'march': '03',
          'apr': '04',
          'april': '04',
          'may': '05',
          'jun': '06',
          'june': '06',
          'jul': '07',
          'july': '07',
          'aug': '08',
          'august': '08',
          'sep': '09',
          'september': '09',
          'oct': '10',
          'october': '10',
          'nov': '11',
          'november': '11',
          'dec': '12',
          'december': '12'
        }

        let formattedValue = pastedValue.toLowerCase();;

        let monthName = '';
        for (monthName in monthsMapping){
          formattedValue = formattedValue.replace(monthName, monthsMapping[monthName]);
        }

        let digits = formattedValue.match(/\d+/g);
        let day = digits[0];
        let month = digits[1];
        let year = digits[2];
        let maxYear = new Date().getFullYear();
        maxYear += 10; // give 10 years in advance

        formattedValue = '';
        if(day != undefined){
          if(day.length < 2){
            formattedValue += ('0' + day + '.')
          }else{
            formattedValue += (day + '.')
          }
        }

        if(month != undefined){
          if(month.length < 2){
            formattedValue += ('0' + month + '.')
          }else{
            formattedValue += (month + '.')
          }
        }

        if(year != undefined){
          if(year.length == 2){
     
            if(parseInt(year) + 2000 > maxYear){
              formattedValue += ('19' + year)
            }else{
              formattedValue += ('20' + year);
            }
            
          }else{
            formattedValue += (year)
          }
        }

        return formattedValue;
      }


      for (var i = elements.length - 1; i >= 0; i--) {
        let element = elements[i];
        if(element.inputmask == undefined){
          im.mask(element);
        }
      }
    }

    function unbindDateMask(){
      const elements = document.getElementsByClassName('date-mask');

      for (var i = elements.length - 1; i >= 0; i--) {
        let element = elements[i];
        if(element.inputmask){
          // revert the value to the original rails date format and remove inputmask
          // let formattedValue = Inputmask.unmask(element.value, { alias: "datetime", inputFormat: "dd/mm/yyyy", outputFormat: "yyyy-mm-dd"});
          Inputmask.remove(element);
          // console.log(formattedValue)
          // element.value = formattedValue;
          // console.log(element.value)
        }
      }
    }


  }
}
