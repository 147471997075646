import flatpickr from "flatpickr";
import { Bulgarian } from "flatpickr/dist/l10n/bg.js"

import { Controller } from "@hotwired/stimulus"

let fpVar = null;
let fpVar2 = null;

export default class extends Controller {
  connect() {
    let datePickers = $('.js-flatpickr');

    let element;
    let maxDate;
    let minDate;

    for (var i = datePickers.length - 1; i >= 0; i--) {
      element = datePickers[i];
      maxDate = $(element).data('max');
      minDate = $(element).data('min');

      flatpickr(element, {
        "locale": Bulgarian,
        altInput: true,
        altFormat: "d/m/Y",
        dateFormat: 'Y-m-d',
        maxDate: maxDate,
        minDate: minDate
      });
    }

    // fpVar = flatpickr('.js-flatpickr', {
    //   "locale": Bulgarian,
    //   altInput: true,
    //   altFormat: "d/m/Y",
    //   dateFormat: 'Y-m-d'
    // });

    fpVar2 = flatpickr('.js-datetime-picker', {
      enableTime: true,
      altInput: true,
      altFormat: "d/m/Y H:i",
      dateFormat: "Y-m-d H:i",
      time_24hr: true,
      "locale": Bulgarian
    })
  }

  teardown() {
    console.log('teardown')
    let flatpickrElements = $('.js-flatpickr, .js-datetime-picker');

    let fp;
    flatpickrElements.each(function() {
      fp = this._flatpickr;
      if(fp !== undefined){
        fp.destroy();
      }
    });
  }

  disconnect() {
  }

  // disconnect() {
  //   if (fpVar !== undefined && fpVar !== null &&  fpVar.length > 0) {
  //     try {
  //       for (var i = fpVar.length - 1; i >= 0; i--) {
  //         fpVar[i].destroy();
  //       }
  //       fpVar = null;
  //     } catch (error) {
  //       console.error('Flatpickr destruction failed. Error:');
  //       console.error(error);
  //     }
  //   }

  //   if (fpVar2 !== undefined && fpVar2 !== null &&  fpVar2.length > 0) {
  //     try {
  //       for (var i = fpVar2.length - 1; i >= 0; i--) {
  //         fpVar2[i].destroy();
  //       }
  //       fpVar2 = null;
  //     } catch (error) {
  //       console.error('Flatpickr destruction failed. Error:');
  //       console.error(error);
  //     }
  //   }


  //   // let flatpickrElements = $('.js-flatpickr, .js-datetime-picker');

  //   // let fp;
  //   // flatpickrElements.each(function() {
  //   // console.log($( this ));

  //   // fp = $( this )._flatpickr;
  //   // if(fp !== undefined){
  //   //   fp.destroy();
  //   // }
  // //});
  // }
}
