import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="modal"
export default class extends Controller {
  connect() {
    let container = $('#payment-container');
    if(container.length == 0){
      // no payment form found
      return;
    }

    let itemInputs = container.find('.js-item-payment-sum');
    let paymentTotalSumInput = container.find('.js-item-payment-total');
    let includeCheckboxes = container.find('.js-payment-item-include');
    let waivePenaltyFeeCheckboxes = container.find('.js-waive-penalty-fee');
    let autofillPaymentButtons = container.find('.js-autofill-payment-btn')
    let paymentItemSums = container.find('.js-item-payment-sum')

    includeCheckboxes.on('change', function(){
      let isChecked = this.checked;
      let parentContainer = $(this).parents('.payment-item-container');
      let sumInput = parentContainer.find('.js-item-payment-sum');

      let contractServiceId = parentContainer.data('contract-service');
      let number = parentContainer.data('number');
      let canBeExcluded = true;
      
      let allInstallments = container.find('.payment-item-container[data-contract-service="' + contractServiceId + '"]');

      allInstallments.each(function(){
      //  console.log($(this).data('number') )
        if(isChecked){
          if($(this).data('number') < number && $(this).hasClass('excluded')){
            // there is an inactive installment of the same service before this one that should be checked first
            canBeExcluded = false;
            return false;
          }
        }else{

          if($(this).data('number') > number && !$(this).hasClass('excluded')){
            // there is an active installment of the same service after this one that should be unchecked first
            canBeExcluded = false;
            return false;
          }
        }
      });

      if(!canBeExcluded){
        $(this).prop('checked', !isChecked);
        //alert('Invalid')
        return;
      }

      if(isChecked) {
        parentContainer.removeClass('excluded');
        sumInput.removeAttr('disabled');
      }else{
        parentContainer.addClass('excluded');
        sumInput.prop('disabled', 'disabled');
      }

      lockPreviousInstallments();
      recalculateTotalSum();
    })

    lockPreviousInstallments();

    function lockPreviousInstallments(){
      // only the last installment of each contract service included in the form can be edited
      // the others should be paid in full due sum

      let allPaymentItems = container.find('.payment-item-container:not(.excluded)');

      let allContractServices = {};

      for (var i = allPaymentItems.length - 1; i >= 0; i--) {
        let contractServiceId = $(allPaymentItems[i]).data('contract-service');
        let number = $(allPaymentItems[i]).data('number');

        allContractServices[contractServiceId] ||= [];
        allContractServices[contractServiceId].push(number);
      }

      let maxNumber = null;
      for (let contractServiceId in allContractServices) {    
        maxNumber = Math.max(...allContractServices[contractServiceId]);

        let allServiceInstallments = container.find('.payment-item-container[data-contract-service="' + contractServiceId + '"]');
        for (var i = allServiceInstallments.length - 1; i >= 0; i--) {
          let installmentContainer = $(allServiceInstallments[i]);
          let sumInput = installmentContainer.find('.js-item-payment-sum');
          let installmentNumber = installmentContainer.data('number');
          let waivePenaltyFeeCheckbox = installmentContainer.find('.js-waive-penalty-fee');
          let waivePenaltyFee = waivePenaltyFeeCheckbox.prop('checked');
          let installmentDueSum = sumInput.data('due-sum');
          if(waivePenaltyFee){
            installmentDueSum = sumInput.data('due-sum-without-penalty-fee');
          }

          if(installmentNumber < maxNumber){

            sumInput.val(installmentDueSum);
            sumInput.prop('readonly', 'readonly');
          }else{
            sumInput.removeAttr('readonly');
          }
        }
      }
    }

    for (var i = waivePenaltyFeeCheckboxes.length - 1; i >= 0; i--) {
      waivePenaltyFee($(waivePenaltyFeeCheckboxes[i]), false);
    }

   waivePenaltyFeeCheckboxes.on('change', function(){
      waivePenaltyFee($(this));
   });

  function waivePenaltyFee(checkbox, changed = true){
    //  console.log('waivePenaltyFee')
      let parentContainer = checkbox.parents('.payment-item-container');
      let sumInput = parentContainer.find('.js-item-payment-sum');

      let waivePenaltyFee = checkbox.prop('checked');
      let penaltyFee = parseFloat(sumInput.data('penalty-fee'));
      let totalDueSum = parseFloat(sumInput.data('due-sum'));
      let dueSumWithoutPenalty = parseFloat(sumInput.data('due-sum-without-penalty-fee'));

      let maxPaymentSum = parseFloat(sumInput.data('max-payment-sum'));

      let currentSum = sumInput.val();
      if(currentSum == '' || currentSum == null){
        currentSum = 0;
      }else{
        currentSum = parseFloat(currentSum);
      }

      if (changed){
        if(waivePenaltyFee){
          if(currentSum.toFixed(2) == totalDueSum.toFixed(2)){
           // console.log('1!')
            sumInput.val((totalDueSum - penaltyFee).toFixed(2));
            sumInput.trigger('input');
          }else if(currentSum.toFixed(2) == maxPaymentSum.toFixed(2)){
          //  console.log('2!')
            sumInput.val((maxPaymentSum - penaltyFee).toFixed(2));
            sumInput.trigger('input');
          }

          sumInput.attr('max', maxPaymentSum - penaltyFee);
        }else{
          if(currentSum.toFixed(2) == (totalDueSum - penaltyFee).toFixed(2)){
          //  console.log('3!')
            sumInput.val(totalDueSum.toFixed(2));
            sumInput.trigger('input');
          }else if(currentSum.toFixed(2) == (maxPaymentSum - penaltyFee).toFixed(2)){
          //  console.log('4!')
            sumInput.val(maxPaymentSum.toFixed(2));
            sumInput.trigger('input');
          }

          sumInput.attr('max', maxPaymentSum);
        }
      }
   }

   // function waivePenaltyFee(checkbox, changed = true){
   //  //  console.log('waivePenaltyFee')
   //    let parentContainer = checkbox.parents('.payment-item-container');
   //    let sumInput = parentContainer.find('.js-item-payment-sum');

   //    let waivePenaltyFee = checkbox.prop('checked');
   //    let penaltyFee = parseFloat(checkbox.data('penalty-fee'));
   //    let totalDueSum = parseFloat(checkbox.data('total-due-sum'));
   //    let maxPaymentSum = parseFloat(checkbox.data('max-payment-sum'));

   //    let currentSum = sumInput.val();
   //    if(currentSum == '' || currentSum == null){
   //      currentSum = 0;
   //    }else{
   //      currentSum = parseFloat(currentSum);
   //    }

   //    if (changed){
   //      if(waivePenaltyFee){
   //        if(currentSum.toFixed(2) == totalDueSum.toFixed(2)){
   //         // console.log('1!')
   //          sumInput.val((totalDueSum - penaltyFee).toFixed(2));
   //          sumInput.trigger('input');
   //        }else if(currentSum.toFixed(2) == maxPaymentSum.toFixed(2)){
   //        //  console.log('2!')
   //          sumInput.val((maxPaymentSum - penaltyFee).toFixed(2));
   //          sumInput.trigger('input');
   //        }

   //        sumInput.attr('max', maxPaymentSum - penaltyFee);
   //      }else{
   //        if(currentSum.toFixed(2) == (totalDueSum - penaltyFee).toFixed(2)){
   //        //  console.log('3!')
   //          sumInput.val(totalDueSum.toFixed(2));
   //          sumInput.trigger('input');
   //        }else if(currentSum.toFixed(2) == (maxPaymentSum - penaltyFee).toFixed(2)){
   //        //  console.log('4!')
   //          sumInput.val(maxPaymentSum.toFixed(2));
   //          sumInput.trigger('input');
   //        }

   //        sumInput.attr('max', maxPaymentSum);
   //      }
   //    }
   // }


   autofillPaymentButtons.on('click', function(){
      let sum = $(this).data('sum');
      let parentContainer = $(this).parents('.payment-item-container');
      let sumInput = parentContainer.find('.js-item-payment-sum');
      if(!sumInput.prop('disabled')){
        sumInput.val(sum);
        recalculateTotalSum();
      }
   })


   paymentItemSums.on('input', function(){
      recalculateTotalSum();
   })

   function recalculateTotalSum(){
      let totalSum = 0;
      let sum = null;
      itemInputs.each(function(){
        if($(this).val() != '' && !$(this).prop('disabled')){
          sum = parseFloat($( this ).val());
          //console.log(sum)
          totalSum += sum;
        }
      })

      paymentTotalSumInput.val(totalSum.toFixed(2));
   }
  }
}
