import { Controller } from "@hotwired/stimulus";
import "@hotwired/turbo-rails"
// Connects to data-controller="modal"
export default class extends Controller {
  connect() {

    document.addEventListener("turbo:before-cache", function () {
      unbindChangeOrderAndReload();
      unbindCrewmanFilters();
    });

    bindChangeOrder();

    bindChangeOrderAndReload();

    bindCrewmanFilters();

    function bindCrewmanFilters(){
      let elements = $('.js-crewman-filter');
      for (var i = elements.length - 1; i >= 0; i--) {
        if(elements[i].dataset.attachedCrewmanFilter != 1){

          elements[i].dataset.attachedCrewmanFilter = 1;
          $(elements[i]).on('change', function(){

            let param_name = $(this).attr('name');
            let param_value = $(this).val();
            let currentHref = window.location.href;
            let currentUrl = new URL(currentHref, "https://starnav.eu")
            currentUrl.searchParams.set(param_name, param_value);

            // console.log( currentUrl.searchParams.toString())

            Turbo.visit(currentUrl.pathname + '?' + currentUrl.searchParams, { action: "replace" });
          })
        }
      }
    }

    function unbindCrewmanFilters(){
      let elements = $('.js-crewman-filter');
      for (var i = elements.length - 1; i >= 0; i--) {
        elements[i].dataset.attachedCrewmanFilter = 0;
        $(elements[i]).off('change');
      }
    }

    function bindChangeOrder(){
      $('.update-order-link').on('click', function(e){
        const href = $(this).attr('href');
        const url = new URL(href, "https://starnav.eu")
        const params = url.searchParams;

        if(params.has('cert_order')){
          // const links = $('.js-cert-link');
          const sortOrder = params.get('cert_order');
          // updateLinks(links, 'cert_order', sortOrder);

          alterBrowserHistory('cert_order', sortOrder);

        }else if(params.has('doc_order')){
          // const links = $('.js-doc-link');
          const sortOrder = params.get('doc_order');
          // updateLinks(links, 'doc_order', sortOrder);

          alterBrowserHistory('doc_order', sortOrder);
        };
      });
    }

    function bindChangeOrderAndReload(){
      let elements = $('.js-order-link');
      for (var i = elements.length - 1; i >= 0; i--) {
        if(elements[i].dataset.attachedAlterBrowserHistory != 1){
          elements[i].dataset.attachedAlterBrowserHistory = 1;
          $(elements[i]).on('click', function(e){
            // console.log('click');
            let param = $(this).data('param');
            let value = $(this).data('value');


            alterBrowserHistory(param, value, () => {
              // location.reload();
              // console.log(location.pathname + location.search);
              Turbo.visit(location.pathname + location.search, { action: "replace" })
            });
          });
        }
      }
    }

    function unbindChangeOrderAndReload(){
      let elements = $('.js-order-link');
      for (var i = elements.length - 1; i >= 0; i--) {
        elements[i].dataset.attachedAlterBrowserHistory = 0;
      }
      elements.off('change');
    }


    function alterBrowserHistory(param_name, param_value, callback){
      let currentHref = window.location.href;
      let currentUrl = new URL(currentHref, "https://starnav.eu")
      currentUrl.searchParams.set(param_name, param_value);

      history.replaceState( history.state , '', currentUrl.pathname + '?' + currentUrl.searchParams);

      let paginationLinks = $('.page-container > a');
      // needed for the pagination plugin; the prarams changed with history.replaceState will not affect the pagination links
      updateLinks(paginationLinks, param_name, param_value);

      if(callback !== undefined){
        callback();
      }
    }

    // function async bindChangeOrderAndReload(){
    //   $('.js-order-link').on('click', function(e){
    //     let param = $(this).data('param');
    //     let value = $(this).data('value');

    //     await Promise.all(
    //       alterBrowserHistory(param, value)
    //     )
    //     // alterBrowserHistory(param, value);
    //     location.reload();
    //   });
    // }

    // function alterBrowserHistory(param_name, param_value){
    //   let currentHref = window.location.href;
    //   let currentUrl = new URL(currentHref, "https://starnav.eu")
    //   currentUrl.searchParams.set(param_name, param_value);

    //   history.replaceState( history.state , '', currentUrl.pathname + '?' + currentUrl.searchParams);

    //   let paginationLinks = $('.page-container > a');
    //   // needed for the pagination plugin; the prarams changed with history.replaceState will not affect the pagination links
    //   updateLinks(paginationLinks, param_name, param_value);
    // }

    function updateLinks(links, param_name, param_value){
      for (var i = links.length - 1; i >= 0; i--) {
        let linkHref = links[i].getAttribute('href');
        let linkUrl = new URL(linkHref, "https://starnav.eu")
        linkUrl.searchParams.set(param_name, param_value);

        links[i].setAttribute('href', linkUrl.pathname + '?' + linkUrl.searchParams);
      }
    }
  }
}
