import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {

    // document.addEventListener("turbo:before-cache", function () {
    // });

    previewUploadedImages();

    function attachPreviewImage(input, target){
      // input.on('change', function(){
      //   return readURL(this, target);
      // })

      input.on('change', attachPreviewImageEventHandler);

      function attachPreviewImageEventHandler(){
        return readURL(this, target);
      }

      let readURL = function(input, container) {
        var reader;
        if (input.files && input.files[0]) {
          reader = new FileReader();
          reader.onload = function(e) {
            return container.attr('src', e.target.result);
          };
          return reader.readAsDataURL(input.files[0]);
        }
      };
    }

    function previewUploadedImages(){
      attachPreviewImage($('.js-image-preview-source'), $('.js-image-preview-target'));

      if( $('.js-image-preview-source').data('click-attached') != 'attached' ){
        $('.js-image-preview-source').data('click-attached', 'attached');
        $('.js-image-preview-target').click(function(){
            $('.js-image-preview-source').click();
        });
      }
    }
  }
}
