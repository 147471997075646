import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="modal"
export default class extends Controller {
  connect() {

    let itemInputs = $('.js-item-payment-sum');
    let paymentTotalSumInput = $('.js-item-payment-total');
    let eurRate = parseFloat($('#eur-rate').val());

    $('.js-contract-item-include').on('change', function(){
      //console.log(1)
      let isChecked = this.checked;
      let parentContainer = $(this).parents('.js-contract-item-container');
      //let sumInput = parentContainer.find('.js-item-payment-sum');

      let contractServiceId = parentContainer.data('contract-service');
      let number = parentContainer.data('number');
      let canBeExcluded = true;
      
     
      if(isChecked) {
        parentContainer.removeClass('excluded');
        parentContainer.find(':input, select').prop('disabled', false);

        //sumInput.removeAttr('disabled');
      }else{
        parentContainer.addClass('excluded');
        parentContainer.find(':input:not(.js-contract-item-include), select').prop('disabled', true);
       // sumInput.prop('disabled', 'disabled');
      }
      payInInstallments();
      maintanenceFields();
      recalculateTotalSum();
    })

    payInInstallments();

    function payInInstallments(){
      let checkboxes = $('.js-contract-item-pay-in-installments');
      for (var i = checkboxes.length - 1; i >= 0; i--) {
        toggleInstallmentsFileds(checkboxes[i]);
      }
    }

    $('.js-contract-item-pay-in-installments').on('change', function(){
      toggleInstallmentsFileds(this);
    });

    $('.js-create-maintenance-record').on('change', function(){
      toggleMaintanenceFields(this);
    });

    function maintanenceFields(){
      let checkboxes = $('.js-create-maintenance-record');
      for (var i = checkboxes.length - 1; i >= 0; i--) {
        toggleMaintanenceFields(checkboxes[i]);
      }
    }

    function toggleMaintanenceFields(checkbox){
        let parentContainer = $(checkbox).parents('.js-contract-item-container');
        let maintanenceContainer = parentContainer.find('.js-maintanence-fields');

        if(checkbox.checked && !checkbox.disabled){
          maintanenceContainer.find(':input, select').prop('disabled', false);
          maintanenceContainer.removeClass('display-none');
        }else{
          maintanenceContainer.find(':input, select').prop('disabled', true);
          maintanenceContainer.addClass('display-none');
        }
    };

    function toggleInstallmentsFileds(checkbox){
        let parentContainer = $(checkbox).parents('.js-contract-item-container');
        let installmentsFieldsContainer = parentContainer.find('.js-installments-fields');

        if(checkbox.checked && !checkbox.disabled){
          installmentsFieldsContainer.find(':input, select').prop('disabled', false);
          installmentsFieldsContainer.removeClass('display-none');
        }else{
          installmentsFieldsContainer.find(':input, select').prop('disabled', true);
          installmentsFieldsContainer.addClass('display-none');
        }

        recalculateTotalSum();
    }

    $('.js-down-payment-percentage').on('input', function(){    

    });

    $('.js-down-payment').on('input', function(){
      let parentContainer = $(this).parents('.js-contract-item-container');
      recalculateDownPaymentPercentage(parentContainer);
    });

    $('.js-down-payment-percentage, .js-purchase-price').on('input', function(){
      let parentContainer = $(this).parents('.js-contract-item-container');
      recalculateDownPayment(parentContainer);
    });

    function recalculateDownPayment(container){
      let downPaymentPercentageInput = container.find('.js-down-payment-percentage');
      if(downPaymentPercentageInput.length == 0){
        return;
      }

      let downPaymentPercentage = downPaymentPercentageInput.val();
      let purchasePrice = container.find('.js-purchase-price').val();
      let downPaymentInput = container.find('.js-down-payment');

      if((purchasePrice != '') && (downPaymentPercentage != '')){
        let downPayment = parseFloat(purchasePrice) * parseFloat(downPaymentPercentage) / 100;
        downPaymentInput.val(downPayment.toFixed(2));
       // downPaymentInput.trigger('input');
      }else{
        downPaymentInput.val(0);
       // downPaymentInput.trigger('input');
      }
      recalculateTotalSum();
    };

    function recalculateDownPaymentPercentage(container){
    //  console.log('recalculateDownPaymentPercentage')
      let downPaymentInput = container.find('.js-down-payment');
      let downPaymentPercentageInput = container.find('.js-down-payment-percentage');
      if(downPaymentPercentageInput.length == 0 || downPaymentInput.length == 0){
        return;
      }

      let downPayment = downPaymentInput.val();
      let purchasePrice = container.find('.js-purchase-price').val();

      if((purchasePrice != '') && (downPayment != '')){
        let downPaymentPercentage = (parseFloat(downPayment) / parseFloat(purchasePrice) * 100);
        // format to precision 4 and remove the trailing zeroes
        downPaymentPercentage = downPaymentPercentage.toFixed(2).replace(/\.?0*$/,'');
        console.log(downPaymentPercentage)

        let downPaymentPercentageOptions = downPaymentPercentageInput[0].options;
        let optionFound = false;
        for (var i = downPaymentPercentageOptions.length - 1; i >= 0; i--) {
          if(downPaymentPercentageOptions[i].value === downPaymentPercentage)
          {
            optionFound = true;
          }
        }

        if(optionFound){
          downPaymentPercentageInput.val(downPaymentPercentage);
        }else{
          // get the original options and append the newly calculated percentage
          let options = downPaymentPercentageInput.data('options').options;

          let htmlOptions = [];
          htmlOptions.push("<option value='" + downPaymentPercentage + "' selected>" + downPaymentPercentage + "</option>");
          for (let i = 0; i < options.length; i++) {
            htmlOptions.push("<option value='" + options[i] + "'>" + options[i] + "</option>");
          }
          
          downPaymentPercentageInput.html(htmlOptions.join());
        }
      }else{
       // downPaymentPercentageInput.val(0);
       // downPaymentPercentageInput.trigger('input');
      }
    }


    $('.js-purchase-price, .js-down-payment, .js-installments-number, .js-interest-percentage').on('input', function(){
      let parentContainer = $(this).parents('.js-contract-item-container');
      recalculateInstallmentSum(parentContainer);
    });

    // $('.js-installments-number').on('change', function(){
    //   let parentContainer = $(this).parents('.js-contract-item-container');
    //   recalculateInstallmentSum(parentContainer);
    // })

    function recalculateInstallmentSum(container){
      let downPaymentInput = container.find('.js-down-payment');
      let purchasePriceInput = container.find('.js-purchase-price');
      let installmentsNumberInput = container.find('.js-installments-number');
      let interestPercentageInput = container.find('.js-interest-percentage');
      let installmentSumInput = container.find('.js-installment-sum');

      if(downPaymentInput.length == 0 || 
        purchasePriceInput.length == 0 || 
        installmentsNumberInput.length == 0 ||
        interestPercentageInput.input == 0 ||
        installmentSumInput.length == 0){
        return;
      }

      $.ajax({
        type: 'GET',
        url: '/contracts/calculate-installment',
        data: {
          purchase_price: purchasePriceInput.val(),
          installments_number: installmentsNumberInput.val(),
          down_payment: downPaymentInput.val(),
          interest_percentage: interestPercentageInput.val()
        }, 
        })
        .done(function (json) {
          installmentSumInput.val(json.installment_sum);
          recalculateTotalSum();
        })
        .fail(function (xhr, status, error) {
            console.log(xhr);
            console.log(error);
        });
    }

    $('.js-currency').on('change', function(){
      recalculateTotalSum();

      let parentContainer = $(this).parents('.js-contract-item-container');

      parentContainer.find('.js-selected-currency').html($(this).val().toUpperCase());
    });

    $('.js-pay-immediately').on('change', function(){
      recalculateTotalSum();
    });

   function recalculateTotalSum(){
     let allItems = $('.js-contract-item-container:not(.excluded)');

     let sum = 0;
     let totalContainer = $('.js-contract-initial-installment-sum');
     for (var i = allItems.length - 1; i >= 0; i--) {
       let itemContainer = $(allItems[i]);
       let payInInstallmentsCheckbox = itemContainer.find('.js-contract-item-pay-in-installments');
       let purchasePriceInput = itemContainer.find('.js-purchase-price');
       let downPaymentInput = itemContainer.find('.js-down-payment');
       let paymentMethodSelect = itemContainer.find('.js-pay-immediately');
       let payImmediately = paymentMethodSelect.val();
       let currency = itemContainer.find('.js-currency').val();

       if(payImmediately !== 'true'){
          console.log(payImmediately)
          continue;
       }

       if(payInInstallmentsCheckbox.prop('checked')){
        if(downPaymentInput.length > 0 && downPaymentInput.val() != ''){
          let downPaymentSum = parseFloat(downPaymentInput.val());
          if(currency == 'eur'){
            downPaymentSum = downPaymentSum * eurRate;
          }
          sum += parseFloat(downPaymentSum.toFixed(2));
        }
       }else{
       // console.log(2)
        if(purchasePriceInput.length > 0 && purchasePriceInput.val() != ''){
          let purchasePriceSum = parseFloat(purchasePriceInput.val());
          if(currency == 'eur'){
            purchasePriceSum = purchasePriceSum * eurRate;
          }
          sum += parseFloat(purchasePriceSum.toFixed(2));
        }
       }
     }

    if(totalContainer.length > 0){
        totalContainer.html(sum.toFixed(2));
    }


   }
  }
}
