import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="modal"
export default class extends Controller {
  connect() {
    let container = $('#due-installments-container');
    if(container.length == 0){
      // no due installments index found
      return;
    }

    let itemInputs = container.find('.js-item-payment-sum');
    let paymentTotalSumInput = container.find('.js-item-payment-total');
    let includeCheckboxes = container.find('.js-payment-item-include');
    let paymentItemSums = container.find('.js-item-payment-sum')

    includeCheckboxes.on('change', function(){
      let isChecked = this.checked;
      let parentContainer = $(this).parents('.installment-item-container');
      let sumInput = parentContainer.find('.js-item-payment-sum');

      let contractServiceId = parentContainer.data('contract-service');
      let number = parentContainer.data('number');
      let canBeExcluded = true;
      
      let allInstallments = container.find('.installment-item-container[data-contract-service="' + contractServiceId + '"]');

      allInstallments.each(function(){
      //  console.log($(this).data('number') )
        if(isChecked){
          if($(this).data('number') < number && $(this).hasClass('excluded')){
            // there is an inactive installment of the same service before this one that should be checked first
            canBeExcluded = false;
            return false;
          }
        }else{

          if($(this).data('number') > number && !$(this).hasClass('excluded')){
            // there is an active installment of the same service after this one that should be unchecked first
            canBeExcluded = false;
            return false;
          }
        }
      });

      if(!canBeExcluded){
        $(this).prop('checked', !isChecked);
        //alert('Invalid')
        return;
      }

      if(isChecked) {
        parentContainer.removeClass('excluded');
        sumInput.removeAttr('disabled');
      }else{
        parentContainer.addClass('excluded');
        sumInput.prop('disabled', 'disabled');
      }

      lockPreviousInstallments();
      recalculateTotalSum();
    })

    lockPreviousInstallments();
    recalculateTotalSum();

    function lockPreviousInstallments(){
      // only the last installment of each contract service included in the form can be edited
      // the others should be paid in full due sum

      let allPaymentItems = container.find('.installment-item-container:not(.excluded)');

      let allContractServices = {};

      for (var i = allPaymentItems.length - 1; i >= 0; i--) {
        let contractServiceId = $(allPaymentItems[i]).data('contract-service');
        let number = $(allPaymentItems[i]).data('number');

        allContractServices[contractServiceId] ||= [];
        allContractServices[contractServiceId].push(number);
      }

      let maxNumber = null;
      for (let contractServiceId in allContractServices) {    
        maxNumber = Math.max(...allContractServices[contractServiceId]);

        let allServiceInstallments = container.find('.installment-item-container[data-contract-service="' + contractServiceId + '"]');
        for (var i = allServiceInstallments.length - 1; i >= 0; i--) {
          let installmentContainer = $(allServiceInstallments[i]);
          let sumInput = installmentContainer.find('.js-item-payment-sum');
          let installmentNumber = installmentContainer.data('number');

          if(installmentNumber < maxNumber){
            // sumInput.val(sumInput.data('due-sum'));
            sumInput.prop('readonly', 'readonly');
          }else{
            sumInput.removeAttr('readonly');
          }
        }
      }
    }

   paymentItemSums.on('input', function(){
      recalculateTotalSum();
   })

   function recalculateTotalSum(){
      let totalSum = 0;
      let sum = null;
      itemInputs.each(function(){
        if($(this).val() != '' && !$(this).prop('disabled')){
          sum = parseFloat($( this ).val());
          totalSum += sum;
        }
      })

      paymentTotalSumInput.val(totalSum.toFixed(2));
   }
  }
}
