// Configure your import map in config/importmap.rb. Read more: https://github.com/rails/importmap-rails
import jQuery from "jquery/dist/jquery"
window.jQuery = jQuery
window.$ = jQuery

import "@hotwired/turbo-rails"
import "./controllers"

import Select2 from 'select2/dist/js/select2.min.js'

// my imports
// import "./main";

// import "./cms/datemask";
// import "./cms/forms";
// import "./cms/image-preview";
// import "./cms/movements";

// import Select2 from 'select2';

//document.addEventListener('turbo:load', function () {
	// Select2();
	// console.log($('#user_staff_role_id'));
	// $('#user_staff_role_id').select2({  width: 'resolve' });
//});

//document.addEventListener('turbo:before-cache', function () {
	// $('#user_staff_role_id').select2('destroy');
//});