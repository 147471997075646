import { Controller } from "@hotwired/stimulus";
// import "@hotwired/turbo-rails"

export default class extends Controller {
  static values = { url: String }
  connect () {
    window.location.href = this.urlValue;
   
    // causes infinite loop on destroy
    // Turbo.visit(this.urlValue, { action: "replace" });
  }
}