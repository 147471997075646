import { Controller } from "@hotwired/stimulus"
import Select2 from "select2/dist/js/select2.min.js";

export default class extends Controller {
  connect() {
    Select2();

    document.addEventListener("turbo:before-cache", function () {
      unbindSelect2();
      unbindFileUploads();
      // unbindExportDocumentsForm();
    });

    // document.addEventListener("turbo:frame-render", function (e) {
    //   // console.log('turbo:frame-render');
    //   bindSelect2();
    // });

    // focus on the search field when select2 is opened
    $(document).on("select2:open", () => {
      document
        .querySelector(".select2-container--open .select2-search__field")
        .focus();
    });


    bindChangeLocale();
    bindSelect2();
    autoSubmitFormOnInputChange();
    bindFileUploads();

    // bindExportDocumentsForm();

    // function bindExportDocumentsForm(){
    //   // $('.js-export-documents-btn').on('click', function(){
    //   //   $(this).addClass('disabled');
    //   // })

    //   // Submit with javascript - the submit button will be disabled until the form submition is completed
    //   $('.js-export-documents').on('submit', function(e){
    //     e.preventDefault();
    //     let formAction = $(this).attr("action");
    //     let submitButton = $(this).find(':submit');
    //     submitButton.addClass('disabled');
    //     console.log(submitButton);

    //     fetch(formAction)
    //     .then(resp => resp.blob())
    //     .then(blob => {
    //       const url = window.URL.createObjectURL(blob);
    //       const a = document.createElement('a');
    //       a.style.display = 'none';
    //       a.href = url;
    //       a.download = 'export.rar';
    //       document.body.appendChild(a);
    //       a.click();
    //       window.URL.revokeObjectURL(url);
    //       submitButton.removeClass('disabled');
    //     })
    //     .catch(() => alert('Something went wrong!'));
    //   })
    // }

    // function unbindExportDocumentsForm(){
    //   $('.js-export-documents').off('submit');
    // }

    function bindFileUploads() {
        let fileInputs = $('.js-file-input');
        for (var i = fileInputs.length - 1; i >= 0; i--) {
            let fileInput = fileInputs[i];

            if($(fileInput).data('click-attached') != 'attached'){
                $(fileInput).on('change', maxFileSizeValidation);
                $(fileInput).data('click-attached', 'attached');
            }
        } 
    }

    function maxFileSizeValidation(){
      const maxFileSizeMb = 15;
      const maxFileSize = 1024 * 1024 * maxFileSizeMb;

      if(this.files[0] != undefined && this.files[0].size > maxFileSize){
         alert("Файлът е твърде голям! Моля, изберете файл по-малък от " + maxFileSizeMb + " Mb");
         this.value = "";
      };
    }

    function unbindSelect2() {
      $("select.select2-hidden-accessible").select2("destroy");
    }

    function unbindFileUploads(){
        $('.js-file-input').off('change', maxFileSizeValidation);
        $('.js-file-input').removeData('click-attached');
    }

    function bindChangeLocale() {
      let selectedLocale = $(".js-change-locale.selected").data("locale");
      selectedLocale ||= "en";
      // console.log(selectedLocale);

      hideOtherLocales();

      $(".js-change-locale").on("click", function () {
        $(".js-change-locale").removeClass("selected");
        $(this).addClass("selected");
        selectedLocale = $(this).data("locale");

        hideOtherLocales();
      });

      function hideOtherLocales() {
        let localeFields = $(".js-locale-fields");

        for (var i = localeFields.length - 1; i >= 0; i--) {
          if (localeFields[i].classList.contains(selectedLocale)) {
            localeFields[i].classList.remove("display-none");
          } else {
            localeFields[i].classList.add("display-none");
          }
        }
      }
    }

    function bindSelect2() {
      let elements = $(".select2");

      // the event gets binded when the page is loaded and when a modal is opened
      let elementsWithoutSelect2Event = [];

      for (var i = elements.length - 1; i >= 0; i--) {
        let element = elements[i];
        if (element.dataset.select2Id == undefined) {
          elementsWithoutSelect2Event.push(element);
          let options = { width: '100%' };
          if(element.classList.contains('error')){
            options.selectionCssClass = 'error';
          }

          $(element).select2(options).on('select2:select', function(){
            // for auto submit form fields
            if(element.dataset.autoSubmit == '1'){
              let parentElements = $(element).parents();
              for (var i = 0; i < parentElements.length; i++) {
                if(parentElements[i].tagName.toLowerCase() == 'form'){
                  $(parentElements[i]).submit();
                }
              }
            }
          });

          $(element).select2(options).on('select2:unselect', function(){
            // for auto submit form fields
            if(element.dataset.autoSubmit == '1'){
              let parentElements = $(element).parents();
              for (var i = 0; i < parentElements.length; i++) {
                if(parentElements[i].tagName.toLowerCase() == 'form'){
                  $(parentElements[i]).submit();
                }
              }
            }
          });

          $(element).on('select2:open', function(){
            // Allow editing the autocomplete value
            if($(element).data('tags') == true){
              $('.select2-container--open').find('.select2-search__field').val(element.value);
            }
          });


          // if(element.id == 'contract_vehicle_id'){
          //   bindNewContractVehicleSelect(element);
          // }
          if(element.id == 'vehicle_type_id'){
            bindVehicleTypeSelect(element);
          }

          if(element.id == 'vehicle_brand_id'){
            bindVehicleBrandSelect(element);
          }

          if(element.id == 'crewman_assignment_company_id'){
            bindNewContractCompanySelect(element, $('#crewman_assignment_vessel_id'));
          }
        }
      }

      for (var i = elementsWithoutSelect2Event.length - 1; i >= 0; i--) {
        let element = elementsWithoutSelect2Event[i];
        let autocompleteUrl = element.dataset.autocomplete;

        // load the data once with ajax when the select2 is opened
        if (autocompleteUrl != undefined) {
          $(element).on("select2:opening", function (e) {
            // console.log($(this))
            let currentTarget = e.currentTarget;
            // console.log($(currentTarget).data("autocomplete"))
            // console.log(currentTarget);

            if ($(currentTarget).data("data-loaded") != "1") {
              // The autocomplete url may have changed
              autocompleteUrl = $(currentTarget).data("autocomplete");
              let blankOptionText = $(currentTarget).data("blankOptionText") || "";
              // e.preventDefault();
              console.log("fetching data");
              loadAutocompleteOptions(currentTarget, autocompleteUrl, blankOptionText);
            }
          });
        }
      }
    }

    function bindNewContractVehicleSelect(element){
      $(element).on('select2:select', function (e) {
        let data = e.params.data;
        let extraOptionsString = data.element.dataset.extra;
        if(extraOptionsString !== undefined && extraOptionsString !== null){
          let extraOptions = JSON.parse(extraOptionsString);
          $('#contract_purchase_price').val(extraOptions.sale_price);
          $('#contract_purchase_price').trigger('input');
        }
      });
    }

    function bindNewContractCompanySelect(element, vesselSelect){
      // When the value of a company is changed, refresh the vessels select
      let selectedCompanyId = null;
      // let vesselSelect = $('#crewman_movement_vessel_id');

      selectedCompanyId = vesselSelect.val();
      $(element).on('select2:select', function (e) {
        let data = e.params.data;

        if (selectedCompanyId != data.id) {
            // reset the office select
            vesselSelect.data("autocomplete", '/autocomplete/company_vessel?company_id=' + data.id);
            vesselSelect.data("data-loaded", '0');
            vesselSelect.val(null).trigger('change');
        }
        selectedCompanyId = data.id;
      });
    }

    function bindVehicleTypeSelect(element){
      if(element.selectedOptions[0] !== undefined && element.selectedOptions[0].label == 'Други'){
        handleOtherOptionSelected(true);
      }else{
        handleOtherOptionSelected(false);
      }

      $(element).on('select2:select', function (e) {
        let data = e.params.data;
        handleOtherOptionSelected(data.text == 'Други');
      });

      function handleOtherOptionSelected(otherIsSelected){
        let otherFieldsContainer = $('.js-vehicle-type-other-fields');
        if(otherIsSelected){
          otherFieldsContainer.removeClass('display-none');
          // otherFieldsContainer.find(':input, select').prop('disabled', false);
        }else{
          otherFieldsContainer.addClass('display-none');
          otherFieldsContainer.find(':input, select').val('');
          // otherFieldsContainer.find(':input, select').prop('disabled', true);
        }
      }
    }

    function bindVehicleBrandSelect(element){
      if(element.selectedOptions[0] !== undefined && element.selectedOptions[0].label == 'Други'){
        handleOtherOptionSelected(true);
      }else{
        handleOtherOptionSelected(false);
      }

      $(element).on('select2:select', function (e) {
        let data = e.params.data;
        handleOtherOptionSelected(data.text == 'Други');
      });

      function handleOtherOptionSelected(otherIsSelected){
        let otherFieldsContainer = $('.js-vehicle-brand-other-fields');
        if(otherIsSelected){
          otherFieldsContainer.removeClass('display-none');
          // otherFieldsContainer.find(':input, select').prop('disabled', false);
        }else{
          otherFieldsContainer.addClass('display-none');
          otherFieldsContainer.find(':input, select').val('');
          // otherFieldsContainer.find(':input, select').prop('disabled', true);
        }
      }
    }
    function loadAutocompleteOptions(element, autocompleteUrl, blankOptionText) {
      console.log("loadAutocompleteOptions");
      $.ajax({
        type: "GET",
        url: autocompleteUrl,
        data: {}, // Any data that is needed to pass to the controller
        dataType: "json",
        success: function (returnedData) {
         // console.log(returnedData)
          let options = returnedData.results;

          // prepend empty option
          options.unshift({ id: "", text: blankOptionText });

          // let selectedOption = $(element).find(":selected");
          // console.log(selectedOption.text())

          // if (selectedOption.val() != "") {
          //   // add the selected options if it's not in the returened list
          //   var found = false;
          //   for (var i = 0; i < options.length; i++) {
          //     if (options[i].id == selectedOption.val()) {
          //       options[i].selected = true;
          //       found = true;
          //       break;
          //     }
          //   }

          //   if (!found) {
          //     options.unshift({
          //       id: selectedOption.val(),
          //       text: selectedOption.text(),
          //       selected: true,
          //     });
          //   }
          // }

          let selectedOptions = $(element).find(":selected");
          if (selectedOptions.length > 0){
            // add the selected options if it's not in the returened list
            let selectedOption = null;
            let found = false;
            let selectedOptionsLength = selectedOptions.length;

            for (var ii = 0; ii < selectedOptionsLength; ii++) {
              selectedOption = selectedOptions[ii];
              found = false;

              for (var i = 0; i < options.length; i++) {
                if (options[i].id == selectedOption.value) {
                  options[i].selected = true;
                  found = true;
                  break;
                }
              }

              if (!found) {
                options.unshift({
                  id: selectedOption.value,
                  text: selectedOption.text,
                  selected: true,
                });
              }
            }
          }

          $(element).empty();

          let extraOptions = '';
          for (var i = 0; i < options.length; i++) {
            // $(element).append(options[i]);
            extraOptions = JSON.stringify(options[i].extra || {});

            if (options[i].selected) {
              $(element).append(
                $("<option data-extra='" + extraOptions + "'></option>")
                  .attr("value", options[i].id)
                  .text(options[i].text)
                  .attr("selected", "selected")
                  .data("extra", options[i].extra)
              );
            } else {
              $(element).append(
                $("<option data-extra='" + extraOptions + "'></option>")
                  .attr("value", options[i].id)
                  .text(options[i].text)
              );
            }
          }

          $(element).data("data-loaded", "1");
          $(element).trigger("change");
          $(element).select2("close");
          $(element).select2("open");
        },
      });
    }

    function autoSubmitFormOnInputChange(){
      let autoSubmitFiles = $('.js-auto-submit-form');
      for (var i = autoSubmitFiles.length - 1; i >= 0; i--) {
        let file = $(autoSubmitFiles[i]);
        if(file.data('change-attached') != 'attached'){

          file.on('change', function(){
            let parentElements = $(this).parents();
            for (var i = 0; i < parentElements.length; i++) {
              if(parentElements[i].tagName.toLowerCase() == 'form'){
                $(parentElements[i]).submit();
              }
            }
          });

          file.data('change-attached', 'attached');
        }
      }
    };

  }
}
